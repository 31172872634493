import React, { useState } from "react";

import { Button } from "@/components/ui/button";
import { useCurrentEditor } from "@tiptap/react";
import {
	BoldIcon,
	Braces,
	Heading1,
	Heading2,
	Heading3,
	ItalicIcon,
	UnderlineIcon,
} from "lucide-react";

import { cn } from "../utils";

import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";

import i18n from "@/i18n";
import { PlaceholderSuggestionList } from "./extensions/placeholder";

const { t } = i18n;

const ToggleButton = ({ isActive, ...props }) => {
	return (
		<Button
			variant="ghost"
			{...props}
			className={cn("hover:bg-slate-100", {
				"bg-slate-200 hover:bg-slate-200": isActive,
			})}
		/>
	);
};

export const TiptapEditorToolbar = ({ readOnly }) => {
	const { editor } = useCurrentEditor();

	const [placeholderFields] = useState(() => [
		{
			id: "calculation",
			type: "calculation",
			description: "Insert a dynamic calculation placeholder",
		},
		...(editor?.commands?.getPlaceholderFields?.() ?? []),
	]);

	const [isPlaceholderPopoverOpen, setIsPlaceholderPopoverOpen] =
		useState(false);

	return (
		<div className="flex gap-2 rounded-t-lg border-b border-slate-200 bg-background p-2">
			<ToggleButton
				title={t("email_editor.toolbar.h1")}
				disabled={readOnly}
				isActive={editor.isActive("heading", { level: 1 })}
				onClick={() => {
					editor.chain().focus().toggleHeading({ level: 1 }).run();
				}}
			>
				<Heading1 />
			</ToggleButton>
			<ToggleButton
				title={t("email_editor.toolbar.h2")}
				disabled={readOnly}
				isActive={editor.isActive("heading", { level: 2 })}
				onClick={() => {
					editor.chain().focus().toggleHeading({ level: 2 }).run();
				}}
			>
				<Heading2 />
			</ToggleButton>
			<ToggleButton
				title={t("email_editor.toolbar.h3")}
				disabled={readOnly}
				isActive={editor.isActive("heading", { level: 3 })}
				onClick={() => {
					editor.chain().focus().toggleHeading({ level: 3 }).run();
				}}
			>
				<Heading3 />
			</ToggleButton>
			<ToggleButton
				title={t("email_editor.toolbar.bold")}
				disabled={readOnly}
				isActive={editor.isActive("bold")}
				onClick={() => {
					editor.chain().focus().toggleBold().run();
				}}
			>
				<BoldIcon />
			</ToggleButton>
			<ToggleButton
				title={t("email_editor.toolbar.italic")}
				disabled={readOnly}
				isActive={editor.isActive("italic")}
				onClick={() => {
					editor.chain().focus().toggleItalic().run();
				}}
			>
				<ItalicIcon />
			</ToggleButton>
			<ToggleButton
				title={t("email_editor.toolbar.underline")}
				disabled={readOnly}
				isActive={editor.isActive("underline")}
				onClick={() => {
					editor.chain().focus().toggleUnderline().run();
				}}
			>
				<UnderlineIcon />
			</ToggleButton>

			<div>
				<Popover
					open={isPlaceholderPopoverOpen}
					onOpenChange={setIsPlaceholderPopoverOpen}
					modal
				>
					<PopoverTrigger>
						<Button
							title={t("email_editor.toolbar.insert_placeholder")}
							variant="ghost"
							disabled={readOnly}
						>
							<Braces />
						</Button>
					</PopoverTrigger>
					<PopoverContent className="flex w-[400px] flex-col gap-2 overflow-y-auto p-2">
						<PlaceholderSuggestionList
							items={placeholderFields}
							command={(item) => {
								if (item.type === "calculation") {
									editor
										.chain()
										.focus()
										.insertPlaceholderCalculation(item.id)
										.run();
								} else {
									editor.chain().focus().insertPlaceholderField(item.id).run();
								}
							}}
						/>
						{/* <div className="border-b border-slate-200 pb-2">
							Insert placeholder variables. Note that you can insert these by
							typing forward slash (/) and then the variable name.
						</div>

						<div
							className="flex flex-col p-2 gap-1 hover:bg-slate-100 rounded"
							role="button"
							tabIndex={0}
							onClick={() => {
								setIsPlaceholderPopoverOpen(false);
								setTimeout(() => {
									editor.chain().focus().insertPlaceholderCalculation().run();
								}, 500);
							}}
						>
							<div>
								<div className="font-bold bg-purple-100 border border-purple-200 px-2 rounded inline-flex">
									Calculation
								</div>
							</div>
							<div className="opacity-75">
								Insert a dynamic calculation placeholder
							</div>
						</div>

						{placeholderFields?.map((field) => (
							<div
								key={field.id}
								className="flex flex-col p-2 gap-1 hover:bg-slate-100 rounded"
								role="button"
								tabIndex={0}
								onClick={() => {
									setIsPlaceholderPopoverOpen(false);
									setTimeout(() => {
										editor
											.chain()
											.focus()
											.insertPlaceholderField(field.id)
											.run();
									}, 500);
								}}
							>
								<div>
									<div className="font-bold bg-blue-100 border border-blue-200 px-2 rounded inline-flex">
										{field.id}
									</div>
								</div>
								<div className="opacity-75">{field.description}</div>
							</div>
						))} */}
					</PopoverContent>
				</Popover>
			</div>
		</div>
	);
};
