import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import i18n from "@/i18n";

import EmailEditor from "@/components/emailEditor";
import UserCommRecipientList from "@/components/userComms/userCommRecipientList";
import UserCommSendPanel from "@/components/userComms/userCommSendPanel";
import { Input } from "@/components/ui/input";
import useUserComm from "@/hooks/notifications/useUserComm";
import useUpdateUserComm from "@/hooks/notifications/useUpdateUserComm";
import useDuplicateUserComm from "@/hooks/notifications/useDuplicateUserComm";
import useDeleteUserComm from "@/hooks/notifications/useDeleteUserComm";
import LoadingPanel from "../loadingPanel";
import DialogModal from "../dialogModal";
import { Button } from "@/components/ui/button";
import { Filter, Mails, Send, Trash2 } from "lucide-react";
import useDialogModal from "@/hooks/useDialogModal";
import { Badge } from "../ui/badge";

const { t } = i18n;

export const UserCommStatusBadge = ({ status, ...props }) => {
	return (
		<Badge
			variant={
				{ draft: "outline-orange", sent: "outline-success" }[status] ??
				"outline"
			}
			{...props}
		>
			{status ? t(`user_comms.status.${status}`) : "..."}
		</Badge>
	);
};

const UserCommsEdit = ({
	id,
	onCancel,
	onChangeSelectedUserComm,
	onSetFilter,
}) => {
	const { dialogElement, alert, confirm } = useDialogModal();

	const [editor, setEditor] = useState();
	const { data: userCommQuery } = useUserComm(id);
	const userComm = userCommQuery?.data;

	const { mutateAsync: updateUserComm } = useUpdateUserComm(id);
	const { mutateAsync: duplicateUserComm, isPending: isDuplicatingUserComm } =
		useDuplicateUserComm(id);
	const { mutateAsync: deleteUserComm, isPending: isDeletingUserComm } =
		useDeleteUserComm(id);

	const isPending = isDuplicatingUserComm || isDeletingUserComm;

	const [subject, setSubject] = useState();
	const [showSendPanel, setShowSendPanel] = useState(false);

	const [isEditorEmpty, setIsEditorEmpty] = useState(true);

	const isLocked = userComm?.status !== "draft";

	const saveDraft = useCallback(
		debounce((payload) => {
			updateUserComm(payload);
		}, 1000),
		[],
	);

	useEffect(() => {
		if (subject) saveDraft({ subject });
	}, [subject]);

	return (
		<DialogModal
			open
			size="lg"
			title={
				<div className="flex items-center gap-2">
					{t("user_comms.edit_user_comm.title")}{" "}
					<UserCommStatusBadge status={userComm?.status} />
				</div>
			}
			onCancel={onCancel}
			cancelDisabled={isPending}
			submitText={
				<>
					<Send size={16} /> {t("generic.send")}
				</>
			}
			submitDisabled={isEditorEmpty || isLocked}
			onSubmit={() => setShowSendPanel(true)}
			footerRenderer={(props) => {
				const { cancelButtonRenderer, submitButtonRenderer } = props;

				return (
					<div className="flex w-full justify-between">
						<div className="flex gap-2">
							<Button
								variant="destructive"
								isLoading={isDeletingUserComm}
								onClick={async () => {
									const confirmDelete = await confirm(
										t("user_comms.actions.delete_user_comm.title"),
										t("user_comms.actions.delete_user_comm.body"),
										{
											submitText: t(
												"user_comms.actions.delete_user_comm.title",
											),
											variant: "destructive",
										},
									);

									if (confirmDelete) {
										await deleteUserComm();
										onCancel();
									}
								}}
							>
								<Trash2 size={16} />{" "}
								{t("user_comms.actions.delete_user_comm.title")}
							</Button>
							<Button
								variant="secondary"
								isLoading={isDuplicatingUserComm}
								onClick={async () => {
									const newUserComm = await duplicateUserComm();
									onChangeSelectedUserComm?.(newUserComm.data.id);
									await alert(
										"Duplicated",
										"Email was successfully duplicated",
									);
								}}
							>
								<Mails size={16} />
								{t("user_comms.actions.duplicate_user_comm.title")}
							</Button>
							{userComm?.status === "sent" && (
								<Button
									variant="secondary"
									onClick={() => {
										onCancel();
										onSetFilter?.(userComm.id);
									}}
								>
									<Filter size={16} />
									{t("user_comms.actions.filter_by_this.title")}
								</Button>
							)}
						</div>
						<div className="flex gap-2">
							{cancelButtonRenderer?.(props, null)}
							{submitButtonRenderer?.(props, null)}
						</div>
					</div>
				);
			}}
			bodyRenderer={() =>
				!userComm ? (
					<LoadingPanel />
				) : (
					<div className="grid grid-cols-[1fr_300px] gap-4">
						<div className="flex flex-col gap-4">
							<Input
								readOnly={isLocked}
								autoFocus
								value={subject ?? userComm.subject}
								onChange={(evt) => setSubject(evt.target.value)}
							/>
							<EmailEditor
								readOnly={isLocked}
								onCreate={({ editor }) => {
									setEditor(editor);
									editor.commands.setContent(userComm.body);
									setIsEditorEmpty(editor.isEmpty);
								}}
								onUpdate={({ editor }) => {
									setIsEditorEmpty(editor.isEmpty);
									saveDraft({ body: editor.getJSON() });
								}}
							/>
						</div>
						<UserCommRecipientList editor={editor} userCommId={id} />
						{showSendPanel && (
							<UserCommSendPanel
								userCommId={id}
								editor={editor}
								onCancel={() => setShowSendPanel(false)}
								onComplete={() => {
									onCancel();
								}}
							/>
						)}
						{dialogElement}
					</div>
				)
			}
		/>
	);
};

export default UserCommsEdit;
